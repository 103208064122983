import { Col, Empty, Row } from "antd";
import { useCallback, useContext, useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { Loader, Produit, SearchBar } from "../../components";
import MainContext from "../../contexts/MainContext";
import AuthContext from "../../contexts/AuthContext";
import PriceContext from "../../contexts/PriceContext";
import SearchContext from "../../contexts/SearchContext";

const ProduitsPageByFamille = () => {
  const { productsByFamille, availableProducts, productsAF, productsPLV, futuresLivraisons } =
    useContext(MainContext);
  const { user } = useContext(AuthContext);
  const { idDevise, activeRole } = useContext(PriceContext);
  const { filters, saveFilters, searchTextProducts, setSearchTextProducts } =
    useContext(SearchContext);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [products, setProducts] = useState([]);

  const [isLoadingProductsByFamille, setIsLoadingProductsByFamille] = useState(true);

  const { marque } = useParams();
  const location = useLocation();
  const tableId = "searchProducts";

  useEffect(() => {
    const tableFilters = filters[tableId] || {};
    if (tableFilters.searchText) {
      setSearchTextProducts(tableFilters.searchText);
    } else {
      setSearchTextProducts("");
    }
    console.log(filters);
  }, [filters, tableId]);

  useEffect(() => {
    setIsLoadingProductsByFamille(true);
    if (productsByFamille) {
      let newProducts;
      if (marque === "all") {
        newProducts = productsByFamille;
      } else if (marque === "dispo") {
        newProducts = availableProducts;
      } else if (marque === "PLV") {
        newProducts = productsPLV;
      } else if (marque === "AF") {
        newProducts = productsAF;
      } else {
        newProducts = productsByFamille.filter((product) => product.CodeFamille === marque);
      }
      setProducts(newProducts);
      setIsLoadingProductsByFamille(false);
    }
  }, [marque, productsByFamille]);

  useEffect(() => {
    if (searchTextProducts) {
      getFilteredProductsByFamille(searchTextProducts);
    } else {
      getFilteredProductsByFamille();
    }
  }, [products, activeRole, searchTextProducts]);

  const getFilteredProductsByFamille = (value) => {
    let filteredProductsTmp;

    if (value) {
      filteredProductsTmp = products.filter((produit) =>
        produit.Intitule.toLowerCase().includes(value.toLowerCase())
      );
    } else {
      saveFilters(tableId, {
        searchText: "",
      });
      filteredProductsTmp = products;
    }
    setFilteredProducts(filteredProductsTmp);
  };

  const handleOnSearch = (value) => {
    saveFilters(tableId, {
      searchText: value,
    });
  };

  const saveScrollPosition = useCallback(() => {
    const scrollPosition = window.scrollY;
    console.log("scrollPosition: ", scrollPosition);
    localStorage.setItem(`scrollPosition-${marque}`, scrollPosition.toString());
  }, [marque]);

  useEffect(() => {
    const handleBeforeUnload = () => {
      saveScrollPosition();
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      saveScrollPosition();
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [location, saveScrollPosition]);

  useEffect(() => {
    const savedScrollPosition = localStorage.getItem(`scrollPosition-${marque}`);
    if (savedScrollPosition && !isLoadingProductsByFamille) {
      console.log("savedScrollPosition: ", savedScrollPosition);

      setTimeout(() => {
        window.scrollTo(0, parseInt(savedScrollPosition, 10));
      }, 100);
    }
  }, [marque, products]);

  return isLoadingProductsByFamille ? (
    <Loader />
  ) : filteredProducts.length > 0 ? (
    <div>
      {console.log(filteredProducts)}
      <SearchBar onSearch={handleOnSearch} />
      <Row gutter={[16, 16]}>
        {filteredProducts.map((produit) => {
          const futureLivraison = futuresLivraisons.find(
            (livraison) => livraison.RefArticle === produit.RefArticle
          );
          return (
            <Col xs={24} sm={12} md={12} lg={8} key={produit.Reference}>
              <Link
                to={`/produits/${produit.Reference}`}
                key={produit.Reference}
                state={{ ...produit }}>
                <Produit
                  produit={{ ...produit }}
                  devise={idDevise}
                  user={user}
                  futureLivraison={futureLivraison || null}
                />
              </Link>
            </Col>
          );
        })}
      </Row>
    </div>
  ) : (
    <>
      <SearchBar onSearch={handleOnSearch} />
      <Empty description={<span>Aucun produit ne correspond à votre recherche</span>} />
    </>
  );
};

export default ProduitsPageByFamille;
